import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSession, useUser } from '@descope/react-sdk'
import { Layout } from '../components/layout/Layout'
import { ROUTE } from './Router.tsx'

import * as analytics from '../lib/analytics'
import * as observability from '../lib/observability'
import { useKyc } from '@/api/kyc.ts'
import { updateInfo } from '@/auth/analyticsUserProfile.ts'
import { useUserCredits } from '@/src-ideation/hooks/useUserCredits.ts'
import { getTenant, putTenant } from '@/api/tenant.ts'
import { useAvatar } from '@/src-ideation/hooks/useAvatar.ts'
import { useAccountInfoStore } from '@/src-ideation/store/useAccountInfoStore.ts'
import { isRunningInElectron } from '@/constants/generalFunctions.ts'
import IntegrationInput from '@/components/integrationInput/IntegrationInput.tsx'

// const MemoizedIntegrationInput = React.memo(IntegrationInput)

export const Root = React.memo(() => {
  const { isAuthenticated, isSessionLoading } = useSession()
  const { user, isUserLoading } = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const { checkKycData } = useKyc()
  const [isKycFetching, setIsKycFetching] = React.useState(false)
  const { getUserCredits } = useUserCredits()
  const { imageSrc } = useAvatar()
  const { setUserImage } = useAccountInfoStore()

  const [showPopUp, setShowPopUp] = React.useState(false)

  const onHandleClose = () => {
    setShowPopUp(false)
    putTenant()
  }

  useEffect(() => {
    if (user && isAuthenticated) {
      analytics.identify(user.userId)
      observability.setUser({
        id: user.userId,
        email: user.email as string,
        name: user.name as string,
      })

      updateInfo(user)

      analytics.track('User Authenticated', {
        identifiedWithId: user.userId,
      })
      setIsKycFetching(true)
      setUserImage(imageSrc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated])

  useEffect(() => {
    if ( user && isAuthenticated && isKycFetching ) {
      getUserCredits()
      checkKycData().finally(() => {
        getTenant()
          .then(response => {
            if ( response ) {
              const { tenantId } = response
              if ( !tenantId ) {
                setShowPopUp(true)
              }
            } else {
              setShowPopUp(true)
              analytics.track('Get Tenant Response Is Undefined Or Null', {})
            }
          })
          .catch(( error: any ) => {
            analytics.track('Error Fetching Tenant', { error })
          })
      })
    }
  }, [isKycFetching]);

  useEffect(() => {
    if (!isAuthenticated) {
      // Navigate only if the user is not trying to authenticate
      if (![ROUTE.SIGN_IN, ROUTE.SIGN_UP].includes(location.pathname)) {
        navigate(ROUTE.SIGN_IN)
      }
    }
  }, [isAuthenticated, navigate, location])

  useEffect(() => {
    setUserImage(imageSrc)
  }, [imageSrc])

  useEffect(() => {
    if (location.pathname === ROUTE.IDEATION) {
      localStorage.setItem('isIdeationRedirect', 'true')
    }
  }, [])

  return (
    <>
      <Layout
        isSessionLoading={isSessionLoading}
        isAuthenticated={isAuthenticated}
        isUserLoading={isUserLoading}
        onHandleClose={onHandleClose}
        showPopUp={showPopUp}
      />
      {isRunningInElectron() && <IntegrationInput/>}
    </>
  )
})
