import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BASE_URL } from '@/lib/config.ts'
import { Message, useChat } from '@/store/useChat.ts'
import { ChatButton } from '../chatButton/ChatButton.tsx'
import * as analytics from '@/lib/analytics.ts'
import * as observability from '@/lib/observability.ts'
import { InnerInputContainer, InputButtonContainer, InputContainer, InputField } from './ChatInputStyle.ts'
import { StreamEvent, SubmitChatMessageOptions, useChatApi } from '@/api/chat.ts'
import { createNewRevision, createNewSession } from '@/api/sessions.ts'
import { callRouter } from '@/api/router-handler.ts'
import { useSessionStore } from '@/store/useSessionStore.ts'
import { PolicyViolation } from '@/src-ideation/components/common/PolicyViolation'
import { PolicyViolationError } from '@/src-ideation/api'
import * as errors from '../../../api/errors.ts'

const ChatSources = {
  General: 'General',
  RAG: 'RAG',
}

interface TextareaAutosizeChatProps {
  setInputHeight: (height: number) => void
  handleSubmit: () => void
  onChange: (event: any) => void
  value: string
  style?: React.CSSProperties
  id?: string
}

const showAlert = (error: any, errorType = '') => {
  if (error instanceof PolicyViolationError || errorType === 'Policy violation') {
    toast.error(<PolicyViolation />)
  } else {
    const defaultAndUnrealisticErrorMessage =
      "I'm sorry... Due to overload on the servers Leo isn't available right now. Please try again in a few seconds."
    toast.error(defaultAndUnrealisticErrorMessage)
  }
}

const TextareaAutosizeChat: React.FC<TextareaAutosizeChatProps> = ({
  setInputHeight,
  handleSubmit,
  value,
  id,
  style,
  ...rest
}) => {
  const textareaRef: any = useRef<HTMLTextAreaElement>(null)
  const [windowHeight, setWindowHeight] = useState(0)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowHeight(window.innerHeight)
    })
  }, [])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      const scrollHeight = textareaRef.current.scrollHeight
      textareaRef.current.style.height = `${scrollHeight}px`
      setInputHeight(scrollHeight)
    }
  }, [value, setInputHeight, windowHeight])

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // Prevents the addition of a new line
      handleSubmit()
    }
  }

  return (
    <InputField
      id={id}
      placeholder="Type here"
      ref={textareaRef}
      rows={1}
      onKeyDown={handleKeyPress}
      autoFocus
      {...{ style, value }}
      {...rest}
    />
  )
}

interface ChatInputProps {
  setInputHeight: (height: number) => void
  setImagePrompt: (prompt: string) => void
}

export const ChatInput: React.FC<ChatInputProps> = ({ setInputHeight, setImagePrompt }) => {
  const {
    fullMessagesLog,
    setFullMessagesLog,
    messages,
    setMessages,
    inputText,
    setInputText,
    isLoading,
    setIsLoading,
    setIsRouterLoading,
    setIsStreaming,
    setProductDescription,
    setIsSuggestionsChosen,
    isSuggestionsChosen,
  } = useChat(store => store)

  const noPartsFound =
    'Sorry, but I couldn’t find relevant parts from existing vendors. Consider making changes to your part requirements to find suitable parts.'

  const { sessionNum, setSessionNum, revisionNum, setRevisionNum, isGeneratedAlready, setIsGeneratedAlready } =
    useSessionStore(store => store)

  const { submitChatMessage, postProcessMessages } = useChatApi()

  const getSessionAndRevision = async () => {
    if (sessionNum === undefined) {
      const newSession = await createNewSession()

      setSessionNum(newSession.sessionNum)
      setRevisionNum(newSession.revisionNum)

      // Why is setIsGeneratedAlready here? - we don't generate nothing in the cad!
      // Is it just copy pasted from ideation?
      setIsGeneratedAlready(false)

      return newSession
    } else if (isCreateNewRevision()) {
      const newRevision = await createNewRevision(String(sessionNum))

      setRevisionNum(newRevision.revisionNum)

      // Why is setIsGeneratedAlready here? - we don't generate nothing in the cad!
      // Is it just copy pasted from ideation?
      setIsGeneratedAlready(false)

      return {
        sessionNum,
        revisionNum: newRevision.revisionNum,
      }
    } else {
      return {
        sessionNum,
        revisionNum,
      }
    }
  }

  const isCreateNewRevision = () => {
    return isGeneratedAlready
  }

  const handleSubmit = async () => {
    if (!inputText.trim().length || isLoading) return
    let errorType = ''

    setIsRouterLoading(true)
    setIsLoading(true)

    const newFullMessages: Message[] = [...fullMessagesLog, { text: inputText.trim(), sender: 'user' }]

    const newMessages: Message[] = [
      // Previous messages
      ...messages,

      // User input
      { text: inputText.trim(), sender: 'user' },

      // Streamed response
      { text: '', sender: 'assistant' },
    ]

    // This function gets called on each event yielded from the stream.
    // Possible events are a tagged union with "type" as the tag.
    async function onStreamEvents(events: StreamEvent[]) {
      setIsStreaming(true)

      const lastIndex = newMessages.length - 1
      for (const event of events) {
        if (event.type === 'references') {
          newMessages[lastIndex].references = event.payload.references
        }

        if (event.type === 'progress') {
          newMessages[lastIndex].text += event.payload.delta
        }

        if (event.type === 'end') {
          if (event.payload.messages?.items?.length > 0) {
            const responseMessages = event.payload.messages.items
            const lastResponseMessage = responseMessages[responseMessages.length - 1]
            newMessages[lastIndex].text = lastResponseMessage.text
          }

          // If messages are invalid, we are continuing to the no rag flow.
          if (event.payload.messages?.isValid) {
            setIsStreaming(false)
          }
        }
      }

      // We can execute the postProcessMessages only on the last messages since it
      // is the only one we change.
      const newLast = await postProcessMessages([newMessages[lastIndex]])
      newMessages[lastIndex] = newLast[0]

      // IMPORTANT: Not saying it is good, but note that we maintain "newMessages"
      // and update "messages" with it.
      // Because this logic ocurs asynchronously the "messages" are not updated when
      // we "setMessages(newMessages)".
      // So, we maintain "newMessages" until the end of this coroutine.
      // TODO: This really needs a refactor as it is very hard to maintain the correct state for "newMessages".
      setMessages(newMessages)
    }

    setInputText('') // clear input
    setMessages([...newMessages]) // immediately show message into chat
    // setRequestMessage(inputText.trim());
    let sessionAndRevision: null | { revisionNum: number; sessionNum: number } = null

    //todo this function is a patch to navigate the user when there is an error. it should be deleted once we solve the chat failure bug.
    // const reloadSession = async (sessionNum: number) => {
    //   const sessionData = await getSessionData(sessionNum);
    //   let historyMessages: any[] = [];
    //
    //   sessionData.session.revisions.map((revision: any) => {
    //     const revisionMessages = revision.chats.sort(
    //       (a: any, b: any) => a.chatNum - b.chatNum,
    //     );
    //     historyMessages = [...historyMessages, ...revisionMessages];
    //   });
    //
    //   if (historyMessages.length !== 0) {
    //     const messagesWithCitations =
    //       await postProcessMessages(historyMessages);
    //     setMessages(messagesWithCitations);
    //   }
    //
    //   setSessionNum(sessionNum);
    //   setRevisionNum(1);
    //   setInputText("");
    // };

    // Send message to the server.
    // It is done recursively according to the responses.
    // Whenever a response returned, we want the state to be changed,
    // then perhaps send another request to the server.
    // TODO: Consider if this should be done in its own hook/chatHook? I think all this logic is misplaced.
    //       Still, we needed to add features so we keep working on this here.
    const recurse = async function (options: SubmitChatMessageOptions) {
      const chatSource = options.withSearch ? ChatSources.RAG : ChatSources.General

      try {
        if (sessionAndRevision == null) {
          sessionAndRevision = await getSessionAndRevision()
        }

        analytics.track('Chat Message Sent', {
          type: 'chat',
          inputText: inputText.trim(),
          chatSource,
        })

        setIsLoading(true)

        // There are multiple possible calls.
        // The first is with search. If the search did not yield any reference,
        // we call the API again without search. In this case, we should not
        // send any new messages because the last message is already registered
        // in the session.
        const messagesToSend = options.withSearch ? newFullMessages : []

        const startTime = performance.now() || 0

        let results: any

        if (options.withSearch) {
          const routerResult = await callRouter(sessionAndRevision!.sessionNum, messagesToSend[0].text)

          if (routerResult.reason === 'Policy Violation') {
            errorType = 'Policy violation'
            setIsRouterLoading(false)
            throw new errors.PolicyViolationError('Policy violation')
          }

          const isPartsNotFound = routerResult.applications.some(
            (app: any) => app.result?.data?.message === noPartsFound
          )
          setIsRouterLoading(false)

          const processedApplications = new Set() // To keep track of processed applications by a unique identifier
          const uniqueResults = [] // To accumulate results for different applications
          
          let messagesListWithSessionMessages = [...messages]

          for (const app of routerResult.applications) {
            const actionFunction = app.function

            // Create a unique identifier for each application (combining function name and specific data or index)
            const uniqueIdentifier = `${actionFunction}-${app.result?.data?.productDescription || app.result?.data?.content || app.result?.messagesList[1]?.text}`

            // Skip if this application has already been processed
            if (processedApplications.has(uniqueIdentifier)) {
              continue
            }

            processedApplications.add(uniqueIdentifier) // Mark this application as processed

            let result

            if (actionFunction === 'technicalQuestionsAnswering') {
              result = await submitChatMessage(
                messagesToSend,
                sessionAndRevision!.sessionNum,
                sessionAndRevision!.revisionNum,
                options
              )
            } else {
              messagesListWithSessionMessages = [...messagesListWithSessionMessages, ...app.result.messagesList]

              result = {
                messages: messagesListWithSessionMessages,
                isValid: true,
              }

              if (actionFunction === 'partSearch' && isPartsNotFound) {
                result.messages.splice(result.messages.length - 2, 1)
              }
            }

            uniqueResults.push(result) // Store the result for the current application
          }

          results = uniqueResults // Set the accumulated unique results
        } else {
          results = await submitChatMessage(
            messagesToSend,
            sessionAndRevision!.sessionNum,

            sessionAndRevision!.revisionNum,
            options
          )
        }

        const endTime = performance.now() || 0
        const totalTime = `${((endTime - startTime) / 1000).toFixed(2)} seconds`

        // Iterate over each unique result
        results.forEach((result: any) => {
          const content = result.messages
          const lastMessage = content[content.length - 1].text

          const citations = content[content.length - 1]?.references?.citations || {}

          // Track analytics for each unique result
          analytics.track('Chat Message Received', {
            isValid: result.isValid,
            invalidReason: result.invalidReason,
            content: lastMessage,
            chatSource,
            citations,
            totalTime,
          })

          // Set messages if the result is valid
          if (result.isValid) {
            setMessages([...content])
          }

          // Find the latest product description from the messages
          const latestProductDescription = [...content] // Use spread operator to avoid modifying the original array
            .reverse()
            .find((obj: any) => obj.sender === 'description' && !obj.chatNum)?.text

          // Set product description if found
          setProductDescription(latestProductDescription)

          // Check and handle the latest product description
          if (latestProductDescription) {
            setImagePrompt(latestProductDescription)
            setFullMessagesLog([...newFullMessages, { text: latestProductDescription, sender: 'assistant' }])
          }

          // Handle invalid result cases
          if (!result.isValid && result.invalidReason === 'Request information is not available') {
            // Re-add a manual message in case of invalid results
            newMessages.push({ text: '', sender: 'assistant' })
            setTimeout(async () => {
              await recurse({ withSearch: false, stream: true, onStreamEvents })
            }, 1)
          } else {
            setIsLoading(false)
          }
        })

        // Activate glow effects or other UI elements if needed
        // activateGlow({ [FIRST_SEEN_ELEMENT.DESIGN_INPUTS]: true, [FIRST_SEEN_ELEMENT.GENERATE]: true });
      } catch (error: any) {
        if (sessionAndRevision == null) {
          sessionAndRevision = await getSessionAndRevision()
        }

        const url = `${BASE_URL}/api/v4/session/${sessionNum}/revision/${revisionNum}/chat`
        observability.captureException('Chat Input Failure', {
          error,
          tags: { url },
          extra: { messages: newFullMessages, chatSource },
        })

        // Track analytics for failed message
        analytics.track('Chat Message Failed', {
          error: error?.message,
          chatSource,
        })

        // Restore input text
        setInputText(inputText.trim())

        // Remove last user's message from chat if needed
        const msgArray = newMessages.filter((msg: Message) => msg.text !== inputText)
        setMessages(msgArray.slice(0, -1))

        setProductDescription('')

        showAlert(error, errorType)

        setIsLoading(false)
        setIsRouterLoading(false)

        // await reloadSession(sessionAndRevision!.sessionNum || 1);
      }
    }

    await recurse({ withSearch: true, stream: true, onStreamEvents })
  }

  const handleSubmitFinally = () => {
    handleSubmit().finally(() => setIsRouterLoading(false))
  }

  if (isSuggestionsChosen) {
    handleSubmitFinally()
    setIsSuggestionsChosen(false)
  }

  const handleTextChange = (event: any) => setInputText(event.target.value)

  return (
    <InputContainer>
      <InnerInputContainer>
        <TextareaAutosizeChat
          setInputHeight={setInputHeight}
          value={inputText}
          onChange={handleTextChange}
          handleSubmit={handleSubmitFinally}
        />
      </InnerInputContainer>
      <InputButtonContainer>
        <ChatButton {...{ onClick: handleSubmitFinally }} />
        <ToastContainer theme="colored" autoClose={4000} />
      </InputButtonContainer>
    </InputContainer>
  )
}
