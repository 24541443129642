import styled from 'styled-components'

interface SpecWrapperProps {
  specHeight: string
}

interface buttonsProps {
  availableLink?: string | undefined
}
export const Wrapper = styled.div`
  background-color: var(--dark-100);
  padding: 1rem;
  position: relative;
  margin: 1rem 0;
  border-radius: 12px;
`

export const TitleStyle = styled.div`
  font-weight: 700;
  color: #ffffff;
  text-decoration: underline;
  font-size: 1.1rem;
`
export const HeadWrapper = styled.div`
margin-bottom: 1rem;
`

export const DetailsStyle = styled.span`
  color: var(--leo-white);
  margin: 1rem 0;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  position: relative;
  margin-top: 1rem;
`

export const ActionButtons = styled.a<buttonsProps>`
  color: #ffffff;
  font-weight: 700;
  font-size: 0.9rem;
  border-radius: 5.25px;
  height: 47px;
  width: 8.1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`

export const DataSheetButton = styled(ActionButtons)`
  background-color: var(--purple-100);
  border: none;
`

export const DownloadPartButton = styled(ActionButtons)`
  background-color: var(--dark-100);
  border: 1px solid var(--border-dark);
`

export const PartImage = styled.img`
  background: white;
  border-radius: 5.25px;
  width: auto;
  display: block;
  height: 100%;
  max-width: 3rem;
  object-fit: contain;
`

export const LargePartImage = styled.img`
  background: white;
  border-radius: 5.25px;
  display: block;
  bottom: 105%;
  z-index: 9999;
  position: absolute;
  max-width: 23rem;
`

export const ReadMore = styled.span`
  color: var(--purple-50);
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`
export const SpecWrapper = styled.div<SpecWrapperProps>`
  margin: 1rem 0;
  height: ${props => (props.specHeight ? '100%' : '0px')};
  //transition: height 0.5s ease;
  //overflow: auto;

  span {
    font-weight: bold;
  }
`

export const PartImageWrapper = styled.div`
  position: relative;
  width: 3rem;
  height:3rem;
  background-color: white;
  border-radius: 5.25px;
  display: flex;
  justify-content: center 

`
