import { Item } from 'react-photoswipe-gallery'
import styles from '../Gallery.module.scss'
import { GalleryImage, IMAGE_ID } from '../Image'
import { ImageSkeleton } from '../ImageSkeleton'
import { useGalleryModal } from '../../../../store/useGalleryModal'
import { useLocation } from 'react-router-dom'
import { ROUTE } from '@/router/Router'

export const Img = ({ url, index, skeletonClass }) => {
  const { setImageUrl, setOpenModal, setCurrentImageUrl } = useGalleryModal()
  const location = useLocation()

  return (
    <Item key={`image-${index}`} original={url} thumbnail={url} alt={`Generated Image ${index + 1}`}>
      {({ ref, open }) => (
        <div
          className={styles.imageContainer}
          ref={ref}
          onClick={event => {
            try {
              if (!event || event.target.id !== IMAGE_ID || !url) return null
              if (location.pathname !== ROUTE.GALLERY) {
                setImageUrl(url)
                setOpenModal(true)
                setCurrentImageUrl(url)
              }
              console.log('Opening gallery with event:', event)
              console.log('open', open)
              return open(event)
            } catch (error) {
              console.error('Error opening gallery:', error)
              console.log(error)
            }
          }}
        >
          {!!url ? <GalleryImage {...{ url }} /> : <ImageSkeleton {...{ className: skeletonClass }} />}
        </div>
      )}
    </Item>
  )
}
