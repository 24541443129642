// import * as observability from "../lib/observability.ts";
import * as analytics from "../lib/analytics.ts";
import * as errors from "./errors.ts";
import { getSessionToken } from "@descope/react-sdk";
import {
  handleSchemaValidationError,
  handleUnknownError,
  Method,
} from "./error-handlers.ts";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "@/router/Router.tsx";
import { PersonalDetails } from "@/components/kycForm/KycForm.tsx";
import { useChat } from "@/store/useChat.ts";
import {BASE_URL} from "@/lib/config.ts";

const KYC_ENDPOINT = `${BASE_URL}/api/v1/user/kyc`;

export function useKyc() {
  const { setIsKycFetching } = useChat((store) => store);

  const navigate = useNavigate();
  const sessionToken = getSessionToken();

  async function checkKycData(): Promise<any> {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
    };

    setIsKycFetching(true);
    try {
      const response = await fetch(KYC_ENDPOINT, options);
      return handleResponse(response, "GET");
    } catch (error: any) {
      // observability.captureException(`Kyc not found`, {
      //   error,
      //   tags: { url },
      // });

      analytics.track("KYC Error", { error });

      throw new errors.UnknownApiError(`Error with fetching data from ${KYC_ENDPOINT}`);
    } finally {
      setIsKycFetching(false);
    }
  }

  async function postKycFormValues(data: PersonalDetails) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify({ data }),
    };

    const response = await fetch(KYC_ENDPOINT, options);
    return handleResponse(response, "POST");
  }

  async function handleResponse(response: Response, method: Method) {
    if (!response.ok) {
      const error = await response.json();
      const args: any = {
        headers: response.headers,
        url: response.url,
        method,
      };

      if (response.status === 404 && error.message === "KYC not found") {
        navigate(ROUTE.KYC_PAGE);
        return;
      }

      if (error.isSchemaValidationError) {
        handleSchemaValidationError(error, args);
      }

      handleUnknownError(error, args);
    }
  }

  return { postKycFormValues, checkKycData };
}
