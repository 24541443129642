import { getSessionToken } from '@descope/react-sdk'
import { BASE_URL } from '@/lib/config.ts'
import * as observability from '@/lib/observability.ts'
import * as analytics from '@/lib/analytics.ts'
import * as errors from '@/api/errors.ts'
import { handleNotFoundError, handleSchemaValidationError, handleUnknownError } from '@/api/error-handlers.ts'

async function createHeaders() {
  const sessionToken = getSessionToken()
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionToken}`,
  }
}

export async function callRouter(sessionNum: number, content: string): Promise<any> {
  const url = `${BASE_URL}/api/v1/session/${sessionNum}/route`

  const options = {
    method: 'POST',
    headers: await createHeaders(),
    body: JSON.stringify({ content }),
  }

  try {
    const response = await fetch(url, options)

    return handleResponse(response)
  } catch (error: any) {
    observability.captureException(`Failed to Call router`, {
      error,
      tags: {
        url,
        authorization: options.headers.Authorization,
      },
    })

    analytics.track('Call Router Error', { error })

    throw new errors.UnknownApiError(`Error with fetching data from ${url}`)
  }
}

async function handleResponse(response: Response) {
  if (!response.ok) {
    const error = await response.json()

    const args: any = {
      headers: response.headers,
      url: response.url,
      method: 'POST',
    }

    if (response.status === 400 && error?.reason === 'Policy Violation') {
      observability.captureException(`Policy Violation Error ${response.url}`, {
        error,
        tags: { url: response.url },
      })

      analytics.track('Policy Violation Error', {
        error,
      })

      return error

      // throw new errors.PolicyViolationError(error.reason)
    }

    if (error.isSchemaValidationError) {
      handleSchemaValidationError(error, args)
    }

    if (response.status === 404) {
      handleNotFoundError(error, args)
    }

    handleUnknownError(error, args)
  }
  return response.json()
}
